<script>
import Base from "@backend/Base.vue";
import BOGen from "@helper/BOGen";
import draggable from "vuedraggable";

export default {
  name: "BoGalleryCategory",
  extends: Base,
  components: {
    draggable,
  },
  data() {
    return {
      Name: "BoGalleryCategory",
      mrLevel: {},
      dataLength: 0
    };
  },
  mounted() {
    this.$set(this.$root, "page", this);
    this.refreshData(() => {this.$set(this.$root.page, "ObjectName", this.replacePageTitle('Kategori', 'Kategori'))});
    this.moduleRedirect();
  },
  methods: {
    endDrag() {
      BOGen.apirest(
        "/" + this.Name,
        {
          data: this.data.data,
          type: "sort",
        },
        (err, resp) => {
          
        },
        "POST"
      );
    },
  },
  watch: {
    "$route.params"() {
      this.$set(this.$root.page, "ObjectName", this.replacePageTitle('Kategori', 'Kategori'));
    },
    "$route.query"() {
      this.refreshData();
    },
    "filter.level"(v) {
      if (!v) return;
      this.search();
    },
    "row.agc_category"(v) {
      if (v.length > 0) {
        $("[name='agc_category']").valid();
      }
    }
  },
};
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
    <div class="row" v-if="!$route.params.id">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <VForm @resp="search">
              <div class="row gutter-10">
                <div class="col-sm-8">
                  <h5 class="card-title">Daftar {{ ObjectName }}</h5>
                </div>
                <div
                  v-if="dataLength > 0"
                  class="col-9 col-sm-4 col-lg-3"
                >
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input
                        type="text"
                        v-model="filter.search"
                        v-on:keyup.enter="search"
                        class="form-control"
                        placeholder="Cari..."
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-info"
                          type="button"
                          @click="search()"
                        >
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="dataLength > 0" class="col-3 col-sm-auto">
                  <router-link :to="{ name: Name }" class="btn btn-warning"
                    >Reset</router-link
                  >
                </div>
              </div>
            </VForm>
          </div>
          <div class="table-responsive ajax-table">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    Kategori
                  </th>
                  <th>{{ fields.agc_is_active }}</th>
                  <th width="120px" v-if="moduleRole('Edit') || moduleRole('Delete')">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v, k) in data.data" :key="k">
                  <td class="number">
                    {{ data.per_page * (data.current_page - 1) + k + 1 }}
                  </td>
                  <td>{{ v.agc_category }}</td>
                  <td>
                    <StatusLabel :status="v.status"></StatusLabel>
                  </td>
                  <td class="btn-action" v-if="moduleRole('Edit') || moduleRole('Delete')">
                    <router-link
                      v-if="page.moduleRole('Edit')"
                      class="icon_action"
                      :to="{ name: Name, params: { id: v.id } }"
                      v-tooltip="'Edit'"
                      ><i class="ti-marker-alt"></i
                    ></router-link>
                    <a
                      v-if="moduleRole('Edit')"
                      href="javascript:;"
                      class="icon_action"
                      @click="changeStatus(k)"
                      v-tooltip="'Change Status'"
                      ><i class="icon-settings"></i
                    ></a>
                    <a
                      v-if="page.moduleRole('Delete')"
                      href="javascript:;"
                      class="icon_action"
                      @click="deleteItem($event, k)"
                      v-tooltip="'Remove'"
                      ><i class="ti-trash"></i
                    ></a>
                  </td>
                </tr>
                <tr v-if="NotFound">
                  <td colspan="99">
                    <h5 class="tc">{{ NotFound }}</h5>
                  </td>
                </tr>
                <tr v-if="data.data === false">
                  <td colspan="99">
                    <LoadingSpinner light></LoadingSpinner>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-body">
            <Pagination
              class="justify-content-end m-0"
              :data="data"
              :limit="3"
              @pagination-change-page="onPaging"
            ></Pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
    <div class="card" v-if="$route.params.id">
      <VForm @resp="submitForm" method="post">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-md-8">
              <h5 class="card-title">
                {{ row.id ? "Ubah" : "Tambah" }} {{ObjectName}}
              </h5>
            </div>
          </div>
          <div class="info"></div>
          <div class="row">
            <div class="col-sm-6">
              <BoField name="agc_category" v-model="row.agc_category" :attr="{
                              placeholder: 'e.g. Sekolah',
                            }" :label="'Kategori'"></BoField>
            </div>
            <div class="col-sm-6">
              <div class="row">
                <div class="col-7">
                  <BoField name="agc_is_active">
                    <radio
                      name="agc_is_active"
                      v-model="row.agc_is_active"
                      option="Y"
                      :attr="validation('agc_is_active')"
                      >Active</radio
                    >
                    <radio
                      name="agc_is_active"
                      v-model="row.agc_is_active"
                      option="N"
                      >Inactive</radio
                    >
                  </BoField>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="text-right">
                <router-link
                  :to="{name: $route.name}"
                  class="fcbtn btn btn-light btn-outline btn-1e btn-rounded mr-2"
                >
                  Kembali
                </router-link>
                <button
                  type="submit"
                  class="fcbtn btn btn-info btn-outline btn-rounded btn-loading"
                >
                  {{ $route.params.id == "add" ? "Tambah" : "Ubah" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </VForm>
    </div>
  </div>
</template>
